<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Sleep Sessions - {{ nc.ctx.patientName }}</h4>
      </div>
      <div class="card-body">
        <full-calendar ref="fullCalendar" :options='calendarOptions'></full-calendar>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import NetClient from '@/util/netclient.js'

  export default {
    components: {
      FullCalendar
    },
    data() {
      return {
        nc: NetClient,
        fcevent: {},
        calendarOptions: {
          eventBackgroundColor: '#006072',
          height: 'auto',
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin // needed for dateClick
          ],
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek'
          },
          initialView: 'dayGridMonth',
          editable: false,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          weekends: true,
          events: this.getEvents,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventDrop: this.handleEventModify,
          eventResize: this.handleEventModify
        }
      }
    },
    methods: {
      getEvents: function(_info, _success, _failure)
      {
        console.log(_info)
        NetClient.doRequest('rest/patient', { action: 'getEvents', start: _info.start, end: _info.end}).then(msg => {
        if(typeof msg.data != 'undefined' && 'OK' == msg.retval) _success(msg.data)
      })

      },
      handleDateSelect(selectInfo) {

        console.log("handleDateSelect " + selectInfo)

      },
      handleEventClick(clickInfo) {
        NetClient.sessionID = clickInfo.event.id
        this.$router.push({name: 'SessionDetail'})
      }

    }
  }
</script>
<style>
  #fullCalendar {
    min-height: 300px;
  }

  .el-loading-spinner .path {
    stroke: #66615B !important;
  }
</style>
